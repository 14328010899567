// Import Global
@import "./global.scss";

// Navbar Component styles
nav{
    @include flexRow();
    height: 100px;
    background-color: transparent;
    z-index: 1;

    img{
        width: 200px;
        position: absolute;
        left: 5rem;
        cursor: pointer;
    }
    
    ul{
        @include flexRow();
        position: absolute;
        right: 5rem;

        li{
            margin: 0 2rem;
            color: #fff;
            cursor: pointer;
            font-family: Noto Sans Display;
            letter-spacing: 2px;
            transition: 1s;

            a{
                color: #fff;
                transition: 1s;
                text-decoration: none;

                &:hover{
                    color: #085d6a;
                }
            }

            &:hover{
                color: #085d6a;
            }
        }
    }

    .menu-icon-btn{
        color: #fff;
        font-size: 3rem;
        position: absolute;
        right: 4rem;
        cursor: pointer;
    }
}

.mobile-menu{
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    z-index: 10000000000;
    height: 100vh;
    background-color: #fff;
    transition: 1s;
    padding: 3rem 0;

    img{
        width: 200px;
        position: absolute;
        left: 5rem;
        cursor: pointer;
    }

    ul{
        li{
            margin: 2rem 0;

            a{
                color: #085d6a;
                transition: 1s;
                text-decoration: none;
            }
        }
    }

    .menu-icon-btn-mobile{
        color: #085d6a;
        font-size: 2rem;
        position: absolute;
        top: 1rem;
        right: 2rem;
        transition: 1s;
        cursor: pointer;

        &:hover{
            color: #e3e3e3;
        }
    }

    .mobile-menu-footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        background-color: #efefef;
        @include flexRow();

        p{
            text-align: center;
            color: #085d6a;
            font-size: 12px;
        }
    }
}

@media (max-width: 992px){
    nav{
        img{
            left: 1rem;
        }

        .menu-icon-btn{
            color: #fff;
            font-size: 3rem;
            position: absolute;
            right: 2rem;
            cursor: pointer;
        }
    }
}