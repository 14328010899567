// Cover Component Styles
.cover-section{
    height: 100vh;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,.9),rgba(0,0,0, .1)), url("../../assets/images/cover/premoldeados-del-este-cover-2.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
    padding: 0 2rem;
    margin-top: -100px;
    
    z-index: -1;
    @include flexColumn();

    h1{
        color: #fff;
        font-family: Noto Sans Display;
        font-size: 5rem;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;
        position: absolute;
        left: 8rem;
        margin-bottom: 10rem;
        text-shadow: 2px 2px 2px #444444;
    }

    h2{
        color: #fff;
        font-family: Noto Sans Display;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 3px;
        position: absolute;
        left: 8rem;
        text-shadow: 2px 2px 2px #444444;
        margin: 3rem 0;
    }

    button{
        width: 300px;
        border: #085d6a 2px solid;
        border-radius: 50px;
        padding: 15px 0;
        color: #fff;
        background-color: #085d6a;
        cursor: pointer;
        font-family: Noto Sans Display;
        position: absolute;
        left: 8rem;
        margin-top: 5rem;
        letter-spacing: 2px;
        transition: 1s;
       

        a{
            color: #fff;
            transition: 1s;
            text-decoration: none;
        }

        &:hover{
            background-color: transparent;
        }
    }
}

@media (max-width: 1024px){
    .cover-section{
        h1, h2, button{
            position: relative;
            left: 0;
            text-align: center;
            margin: 0;
        }

        h1{
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }

        h2{
            font-size: 1rem;
            margin-bottom: 2rem;
        }
    }
}