// Import global Styles
@import "./global";

// About Us Component Styles
.about-us{
    width: 100%;
    @include flexColumn();
    margin: 3rem 0 0 0;
    padding: 5rem 0;
    text-align: center;

    h2{
        font-weight: bold;
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
        display: flex;
        align-items: center;

        .about-icon{
            color:  #085d6a;
            font-size: 3rem;
            margin: 0 1rem;
        }
    }

    span{
        height: 5px;
        width: 70px;
        background-color: #085d6a;
        font-size: 2rem;
    }

    .about-us-icon{
        font-size: 2.5rem;
        margin: 2rem 0;
        color: #085d6a;
    }

    h3{
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
        display: flex;
        align-items: center;
    }

    .about-us-container{
        width: 100%;
        @include flexRow();

        .about-us-text{
            width: 80%;
            margin: 2rem;
            @include flexColumn();

            p{
                color: gray;
                font-family: "Public Sans", sans-serif;
                font-size: 15px;
                width: 60%;
                text-align: center;
            }
        }
    }
}

// Media Querys
@media screen and (max-width: 992px) {
    .about-us{
        margin: 1rem 0;
        padding: 1rem 0;

        h2{
            font-size: 2rem;
        }
    }
}