// Import Global
@import "./global.scss";

// Products Carousel Component Styles
.products-carousel{
    padding: 3rem 10rem;
    width: 70%;
    margin: auto;

    h2{
        font-weight: bold;
        font-size: 2rem;
        color: #085d6a;
    }

    .carousel-premoldeados-del-este{
        height: 700px;

        img{
            height:700px;
            width: 100%;
            object-fit: cover;
        }
    }
}

@media (max-width: 1024px){
    .products-carousel{
        padding: 3rem 0;

        h2{
            font-weight: bold;
            font-size: 1.5rem;
            color: #085d6a;
        }

        .carousel-premoldeados-del-este{
            height: auto;
    
            img{
                height: 400px;
                width: auto;
                object-fit: cover;
            }
        }
    }
}