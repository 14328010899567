// General styles
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    outline: 0;
}

body {
    overflow-x: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none;
}
  
body::-webkit-scrollbar {
    display: none;
}

html {
    scroll-behavior: smooth;
}

// Import SASS files
@import './components/_navbar.scss';
@import './components/_cover.scss';
@import './components/_products-cover.scss';
@import './components/_whatsapp-button.scss';
@import './components/_products-carousel.scss';
@import './components/_contact.scss';
@import './components/_footer.scss';
@import './components/about-us';
@import './components//card-product';

// Import Pages Components Styles
@import './components//our-products';
