// Import Mixins
@import "./_global.scss";

// Product Card Component Styles
.card-product{
    margin: 0 1rem;
    padding-top: 2rem;

    .card-image{
        height: 300px;
        width: auto;
        transition: 1s;

        &:hover{
            scale: 1.1;
        }
    }

    .card-title{
        color: #085d6a;
    }

    .card-button{
        border: #085d6a solid 2px;
        background-color: #085d6a;
        transition: 1s;

        &:hover{
            border: #085d6a solid 2px;
            color: #085d6a;
            background-color: #fff;
        }
    }
}

// Modal Component Styles
.modal-title-premoldeados-del-este{
    color: #085d6a !important;
}

.modal-body-premoldeados-del-este{
    @include flexRow();

    img{
        width: 500px;
        margin: 3rem;
    }

    .premoldeados-del-este{
        p{
            @include flexRow();
            justify-content:flex-start;
            color: gray;

            .premoldeados-del-este-icon{
                color: #085d6a;
                font-size: 3rem;
            }
        }
    }
}

// Media Querys
@media screen and (max-width: 992px) {
    .modal-body-premoldeados-del-este{
        @include flexColumn();
    
        img{
            width: 500px;
            margin: 2rem 0;
        }
    }
}