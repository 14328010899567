// Import Mixins
@import "./global.scss";

// Footer Styles
.footer{
    width: 100%;
    background-color: #085d6a;
    padding: 2rem 0;
    margin-top: 3rem;
    color: #fff;
    font-size: 12px;
    text-align: center;
    @include flexColumn();

    .footer-row{
        width: 80%;
        margin: 2rem 0;
        @include flexCenter();

        .footer-col{
            width: 25%;
            @include flexColumn();

            .footer-icon{
                margin: 1rem;
            }
        }
    }
}

// Media Querys
@media (max-width: 1024px){
    .footer{
        padding: 1rem 0;
        margin-top: 1rem;

        .footer-row{
            flex-direction: column;
            text-align: center;
            margin: 1rem 0;
    
            .footer-col{
                width: 100%;
                margin-top: 1rem 0;
                padding: 1rem 0;
                @include flexColumn();
    
                .footer-icon{
                    margin: 1rem;
                }
            }
        }
    }
}