// Import Mixins
@import "./_global.scss";

// Product Page Styles
.our-products-row{
    width: 100%;
    padding: 3rem;
    @include flexColumn();

    h2{
        font-weight: bold;
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
    }

    span{
        height: 5px;
        width: 70px;
        background-color: #085d6a;
    }

    .product-cards-container{
        width: 100%;
        margin: 2rem 0;
    }
}

// Media Querys
@media screen and (max-width: 992px) {
    // Product Page Styles
    .our-products-row{
        h2{
            font-size: 2rem;
        }

        
        .product-cards-container{
            width: 100%;
            margin: 2rem 0;
            @include flexColumn();
        }
    }
}