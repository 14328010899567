// Import Mixins
@import "./_global.scss";

// Contact Styles
.contact-form{
    width: 100%;
    @include flexColumn();
    margin: 3rem 0;
    padding: 5rem 0;

    h2{
        font-weight: bold;
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
    }

    span{
        height: 5px;
        width: 70px;
        background-color: #085d6a;
    }

    form{
        width: 50%;
        @include flexColumn();

        .form-alert{
            width: 80%;
            margin-bottom: 1rem;
        }

        .input-text{
            width: 80%;
            margin: 1rem 0;
        }

        .text-area{
            padding: 2rem 0;
        }

        button{
            border: 2px solid #085d6a;
            background-color: #085d6a;
            transition: 1s;

            &:hover{
                border: 2px solid #085d6a;
                background-color: #fff;
                color: #085d6a;
            }
        }
    }
}

// Media Querys
@media screen and (max-width: 992px) {
    .contact-form{
        margin: 1rem 0;
        padding: 1rem 0;

        h2{
            font-size: 2rem;
        }

        form{
            width: 80%;
        }
    }
}